let counter = 0;

$(".logo").each((index, element) => {
    let string = element.innerHTML;
    let html = "";
    for (let i = 0; i < string.length; i++) {
        html += "<span class='logo-" + (counter + 1) + "'>" + string[i] + "</span>";
        counter = (counter + 1) % 8;
    }
    element.innerHTML = html;
});